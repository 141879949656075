import client from "../../client"
import qs from 'qs'

const Api = {

  //根据权限菜单Id获取权限菜单。
  getRight: "/api/URCService/Right/GetRight",

  //获取权限菜单列表
  getRights: "/api/URCSecurityService/Security/GetGreaterThanFunctionMenuOfShop",

  //添加角色。
  createRole: "/api/ProductService/ShopRole/CreateRole",

  //修改角色。
  updateRole: "/api/ProductService/ShopRole/UpdateRole",

  //删除角色。
  deleteRole: "/api/ProductService/ShopRole/DeleteRole",

  //根据角色Id获取角色。
  getRole: "/api/ProductService/ShopRole/GetById",

  //获取角色分页数据
  getRolePageList: "/api/ProductService/ShopRole/GetListPaged",

  //获取角色拥有的权限Id集合。
  getRightsFromRole: "/api/URCService/Role/GetRightsFromRole",

  //更改角色权限。(原权限树将被新权限树覆盖)
  updateRightsFromRole: "/api/ProductService/ShopRole/UpdateRightsFromRole",

  
}

/** 权限begin */

//获取权限
export const getRight = (id) => {
  return client.request({
    url: Api.getRight,
    params: { id },
    method: 'GET'
  });
}

//获取所有权限得列表
export const getRights = (appId,rlId) => {
  return client.request({
    url: Api.getRights,
    params: { appId,rlId },
    method: 'GET'
  });
}
/** 权限end */

/** 角色begin */
//添加角色
export const createRole = ({roleId,roleName,description}) => {
  return client.request({
    url: Api.createRole,
    data: { roleId,roleName,description },
    method: 'post'
  })
}
//修改角色
export const updateRole = ({ roleId,roleName,description}) => {
  return client.request({
    url: Api.updateRole,
    data: { roleId,roleName,description },
    method: 'post'
  })
}
//获取角色得分页列表
export const getRolePageList = ({ searchKey, skipCount, maxResultCount, sorting }) => {
  return client.request({
    url: Api.getRolePageList,
    data: { searchKey, skipCount, maxResultCount, sorting },
    method: 'post'
  })
}
//获取角色权限
export const getRightsFromRole = (roleId) => {
  return client.request({
    url: Api.getRightsFromRole,
    params: { roleId },
    method: 'GET'
  });
}
//修改角色权限
export const updateRightsFromRole = ({ roleId,rightIds}) => {
  return client.request({
    url: Api.updateRightsFromRole,
    data: { roleId,rightIds },
    method: 'post',
    disableAutoError:true
  })
}

//删除角色
export const deleteRole = (ids) => {
  return client.request({
    url: Api.deleteRole,
    data:ids,
    method: 'post',
    disableAutoError:false
  })
}
/** 角色end */
