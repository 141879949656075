<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.user_role_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div class="mb-2">
        <a-row type="flex"
               justify="space-between"
               align="bottom">
          <a-col>
            <a-form layout="inline">
              <a-form-item class="mt-1">
                 <a-input :placeholder="$t('common.query')"
                         allow-clear
                         v-model:value="tableData.searchData.searchKey"
                         style="width: 250px"
                         @keyup.enter="handleSearch"
                         ></a-input>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-button type="primary"
                          @click="handleSearch">{{ $t('common.query') }}</a-button>
              </a-form-item>

            </a-form>
          </a-col>
          <a-col>
             <a-button type="primary" ghost @click="handleOpenCreateModal">{{ $t('common.create') }}</a-button>
          </a-col>
        </a-row>
      </div>

      <div>
        <a-table
          :columns="columns"
          size="small"
          :data-source="tableData.tableList"
          :pagination="false"
          :scroll="{ x: 500, y: wrap.contentHeight - 100 }"
          :rowKey="(record, index) => record.roleId"
          :loading="tableData.loading"
        >
          <template v-slot:operation="{ record }">
            <a-dropdown-button @click="handleOpenEditModal(record)">
              {{ $t('common.edit') }}
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item key="1" :record="record">
                    {{ $t('common.delete') }}
                  </a-menu-item>
                  <a-menu-item key="2" :record="record">
                    {{ $t('user.assign_role_permissions') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>

      <!-- 新增编辑框 -->
      <a-modal
        v-model:visible="modalData.visible"
        :confirm-loading="modalData.loading"
        :title="modalData.formData.roleId > 0 ? $t('common.edit') : $t('common.add')"
        :maskClosable="false"
        @ok="handleSave"
      >
        <a-form
          :model="modalData.formData"
          :label-col="{ span: 5 }"
          ref="refEditModalForm"
          :rules="roleRules"
        >
          <a-form-item :label="$t('user.role_name')" name="roleName">
            <a-input
              v-model:value="modalData.formData.roleName"
              :placeholder="$t('user.role_name')"
            ></a-input>
          </a-form-item>
          <a-form-item :label="$t('user.description')" name="description">
            <a-textarea v-model:value="modalData.formData.description" :placeholder="$t('user.description')" :rows="4" />
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 分配权限框 -->
      <a-modal
        v-model:visible="allowRightModalData.visible"
        :confirm-loading="allowRightModalData.loading"
        :title="`${$t('user.assign_role_permissions')}(${allowRightModalData.formData.roleName})`"
        :maskClosable="false"
        @ok="handleSaveAllowRight"
        :width="500"
      >
        <div :class="{ overflowList: allowRightModalData.showOverflowList }">
          <a-form
            :model="allowRightModalData.formData"
            :label-col="{ span: 5 }"
            ref="refAllowRightModalForm"
          >
            <a-tree
              v-if="rightTree.length"
              checkable
              :tree-data="rightTree"
              @check="checkRight"
              v-model:checkedKeys="checkedKeys"
              v-model:expandedKeys="expandedKeys"
              :checkStrictly="true"
              ref="refRightTree"
            >
            </a-tree>
          </a-form>
        </div>
      </a-modal>
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <CPager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import {
  Table,
  Button,
  Modal,
  Input,
  Select,
  Card,
  Row,
  Col,
  Form,
  Radio,
  Switch,
  InputNumber,
  message as Msg,
  Popconfirm,
  Tree,
  Dropdown,
Menu,
} from "ant-design-vue";
import CPager from "../components/CPager.vue";
import Content from "../components/Content.vue";
import {
  defineComponent,
  onMounted,
  reactive,
  nextTick,
  ref,
} from "vue";
import { useI18n } from "vue-i18n/index";
import { getName } from "../../utils/general";
import { useStore } from "vuex";
import {
  getRolePageList,
  createRole,
  updateRole,
  deleteRole,
  updateRightsFromRole,
  getRights,
  getRightsFromRole,
} from "../../api/modules/user/index";

export default defineComponent({
  name: "role_manager",
  components: {
    ATable: Table,
    AButton: Button,
    AModal: Modal,
    CPager,
    AInput: Input,
    AInputGroup: Input.Group,
    ASelect: Select,
    ACard: Card,
    ARow: Row,
    ACol: Col,
    Content,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ARadio: Radio,
    ARadioOption: Radio.Option,
    ARadioButton: Radio.Button,
    ARadioGroup: Radio.Group,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    APopconfirm: Popconfirm,
    ATree: Tree,
    ATextarea: Input.TextArea,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const refEditModalForm = ref();
    const columns = [
      {
        dataIndex: "roleName",
        width: 150,
        fixed: "left",
        title: () => vueI18n.t('user.role_name'),
      },
      {
        dataIndex: "description",
        width: 150,
        title: () => vueI18n.t('user.description'),
      },
      {
        width: 150,
        title: () => vueI18n.t('common.operation'),
        slots: {
          customRender: "operation",
        },
      },
    ];

    const tableData = reactive({
      tableList: [],
      loading: false,
      searchData:{
        searchKey:"",
        appId:null,
        roleTypeId:null
      },
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const getDefaultRoleObj = () => {
      return {
        appId: null,
        roleId: 0,
        roleName: "",
        description: "",
        roleTypeId: null,
        isDefault: false,
        rlId:0
      };
    };
    const modalData = reactive({
      visible: false,
      loading: false,
      formData: getDefaultRoleObj(),
    });

    const setRoleFromRecord = (record = {}) => {
      let { appId, roleId, roleName, description, roleTypeId, isDefault,rlId } =
        record;
      Object.assign(modalData.formData, {
        appId,
        roleId,
        roleName,
        description,
        roleTypeId,
        isDefault,
        rlId
      });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    //table列表
    const getRoleList = () => {
      tableData.loading = true;
      var searchData = {
        skipCount:tableData.pageData.skipCount,
        maxResultCount: tableData.pageData.maxResultCount,
        searchKey: tableData.searchData.searchKey
      };

      getRolePageList(searchData)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          tableData.tableList = items;
          tableData.pageData.totalCount = parseInt(totalCount);
          tableData.loading = false;
        })
        .catch(() => {
          tableData.loading = false;
        });
    };

    const handleSearch = () => {
      tableData.pageData.currentIndex = 1;
      tableData.pageData.skipCount = 0;
      getRoleList();
    };

    const handlePage = (pageData) => {
      tableData.pageData.skipCount = pageData.skipCount;
      tableData.pageData.maxResultCount = pageData.maxResultCount;
      getRoleList();
    };

    const handleMenuClick = (e) => {
      let { key, item } = e
      let { record } = item
      switch (key) {
        case '1':
          Modal.confirm({
            title: vueI18n.t("common.operation"),
            content: vueI18n.t("common.are_you_sure"),
            okText: vueI18n.t("common.confirm"),
            cancelText: vueI18n.t("common.cancel"),
            onOk: () => {
              return deleteRole([ record.roleId ]).then(() => {
                Msg.success(vueI18n.t("common.succeed"));
                handleSearch();
              }).catch((data) => {
                if (data.msg) {
                  Msg.error(data.msg);
                }
              });
            },
          });
          break;
        case '2':
          handleOpenAllRightModal(record)
          break;
        default:
          break;
      }
    }

    /*打开模态框之前*/
    const beforeModalOpen = () => {
      nextTick(() => {
        if (refEditModalForm.value && refEditModalForm.value.resetFields) {
          refEditModalForm.value.resetFields();
        }
      })
    };

    /*打开模态框*/
    const handleOpenCreateModal = () => {
      // modalData.formData = {};
      if (modalData.formData.roleId > 0) {
        modalData.formData = getDefaultRoleObj();
      }
      modalData.visible = true;
      beforeModalOpen();
    };

    const handleOpenEditModal = (record) => {
      setRoleFromRecord(record);
      modalData.visible = true;
      beforeModalOpen();
    };

    const handleSave = () => {
      refEditModalForm.value
        .validate()
        .then(() => {
          modalData.loading = true;
          return modalData.formData.roleId > 0
            ? updateRole(modalData.formData)
            : createRole(modalData.formData);
        })
        .then(() => {
          modalData.loading = false;
          modalData.visible = false;
          handleSearch();
        })
        .catch(() => {
          modalData.loading = false;
        });
    };

    /*验证规则 */
    const roleRules = {
      roleName: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("user.role_name"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
      description: [
        {
          required: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("user.description"),
            ]);
          },
          trigger: ["blur", "change"],
        },
      ],
    };

    onMounted(() => {
      handleSearch();
    });

    /** 分配权限begin  */
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    const checkedKeys = ref([]);
    const allowRightModalData = reactive({
      visible: false,
      loading: false,
      showOverflowList: false,
      formData: {
        roleId: 0,
        roleName: "",
        appId: null,
        appName: "",
        rightIds: [],
        rlId:0
      },
      treeData: {
        existRightIds: [],
      },
    });

    const rightCache = {
      //appId键
      rights: {},
    };
    const rightTree = ref([]);
    const refAllowRightModalForm = ref();

    const toTree = (list) => {
      let node;
      const map = {};
      const tree = [];
      for (let i = 0; i < list.length; i++) {
        map[list[i].id] = i;
      }
      for (let i = 0; i < list.length; i++) {
        node = list[i];
        if (node.parentId > 0) {
          //防止该parentId没有节点
          if (list[map[node.parentId]]) {
            const children = list[map[node.parentId]].children || [];
            list[map[node.parentId]].children = [...children, node];
          }
        } else {
          tree.push(node);
        }
      }
      return tree;
    };

    const rightData = reactive({
      loading: false,
      rights: [],
    });

    const getRightTreeData = () => {
      //nothing
      rightData.loading = true;
      let appId = allowRightModalData.formData.appId;
      let rlId = allowRightModalData.formData.rlId;
      var cacheKey = allowRightModalData.formData.appId + "";
      if (rightCache.rights[cacheKey]) {
        rightData.rights = rightCache.rights[cacheKey];
        getRightTree();
      } else {
        // console.log(appId+" "+rlId);
        getRights(appId,rlId)
          .then((res) => {
            //权限类型(1.系统,2.模块,3.菜单,4.功能,5.资源)
            let filterRightType = 3;
            let rights = res.result.filter((r) => r.isDelete == false && r.rightType<=filterRightType);
            rightData.rights = rights;
            rightCache.rights[cacheKey] = rightData.rights;
          })
          .catch((err) => {
            rightData.rights = [];
            // console.error("load province error");
            throw err;
          })
          .finally(() => {
            rightData.loading = false;
            if (
              rightData.rights.findIndex(
                (x) => x.rightId == modalData.formData.parentRightId
              ) >= 0
            ) {
              //keep it
            } else {
              modalData.formData.parentRightId = null;
            }
            getRightTree();
          });
      }
    };

    //渲染权限树
    const getRightTree = () => {
      let rights = rightData.rights;
      if (rightData.rights && rightData.rights.length) {
        rights = rightData.rights.map((r) => {
          let item = {
            id: r.rightId,
            parentId: r.parentRightId,
            key: r.rightId,
            title: r.rightShowName,
            value: r.rightId,
            isDelete: r.isDelete,
            slots: {
              title: "tree-select-title",
            },
            editData: r,
            children: [],
          };
          return item;
        });
        rights = rights.filter((r) => r.isDelete == false);
        let subTree = toTree(rights);
        if (subTree.length > 0 && rightTree.value) {
          rightTree.value = subTree;
        }
      }
      //展开
      expandedKeys.value = rights.map((x) => x.id);
      allowRightModalData.showOverflowList = rights.length > 20;
    };

    /*打开模态框*/
    const handleOpenAllRightModal = (record) => {
      //1.打开模态框
      //2.加载权限树（并勾选上已有权限）
      allowRightModalData.formData.roleId = record.roleId;
      allowRightModalData.formData.roleName = record.roleName;
      allowRightModalData.formData.appId = record.appId;
      allowRightModalData.formData.appName = record.appName;
      allowRightModalData.formData.rlId = record.rlId;
      getRoleRightData();
      getRightTreeData();
      allowRightModalData.visible = true;
    };

    const getRoleRightData = () => {
      getRightsFromRole(allowRightModalData.formData.roleId)
        .then((res) => {
          let rightIds = res.result;
          allowRightModalData.treeData.existRightIds = rightIds;
          checkedKeys.value = rightIds;
        })
        .catch((err) => {
          allowRightModalData.treeData.existRightIds = [];
          throw err;
        })
        .finally(() => {
          allowRightModalData.loading = false;
          if (
            rightData.rights.findIndex(
              (x) => x.rightId == modalData.formData.parentRightId
            ) >= 0
          ) {
            //keep it
          } else {
            modalData.formData.parentRightId = null;
          }
          getRightTree();
        });
    };

    const checkRight = (keys, e) => {
      let rightCode = e.node.dataRef.editData.rightCode;
      if (e.checked) {
        //1.选中时所有上级都应该选中
        let parentCodes = getParentCodes(5, rightCode);
        //console.log(parentCodes);
        let parentRights = rightData.rights.filter(
          (x) => parentCodes.indexOf(x.rightCode) > -1
        );
        //  console.log(parentRights);
        let parentRightIds = parentRights.map((x) => x.rightId);
        //以解构方式合并
        //let checkKeys= [...(keys.checked || []),...parentRightIds];
        let checkKeys = keys.checked || [];
        if (parentRightIds.length > 0) {
          checkKeys = checkKeys.concat(parentRightIds);
          //数组去重
          checkKeys = Array.from(new Set(checkKeys));
        }
        //2.选中时所有下级都应该选中
        let regex = new RegExp(`^(${rightCode})[0-9]*$`);
        let chidrenRights = rightData.rights.filter((x) =>
          regex.test(x.rightCode)
        );
        var chidrenRightIds = chidrenRights.map((p) => p.rightId);
        checkKeys = Array.from(new Set([...checkKeys,...chidrenRightIds]));
        checkedKeys.value = checkKeys;
      } else {
        //取消时,所有下级都会取消
        //0001   00010001  000100010002 startwith(0001)
        let regex = new RegExp(`^(${rightCode})[0-9]*$`);
        //方式1
        let chidrenRights = rightData.rights.filter((x) =>
          regex.test(x.rightCode)
        );
        //方式2
        //let chidrenRights = rightData.rights.filter(x=>x.rightCode.startsWith(rightCode));
        let myCheckKeys = keys.checked || [];
        var chidrenRightIds = new Set(chidrenRights.map((p) => p.rightId));
        let diffrenceKeySets = new Set(
          myCheckKeys.filter((x) => !chidrenRightIds.has(x))
        );
        // console.log("已经选中:"+myCheckKeys);
        // console.log("子Id:"+chidrenRightIds);
        // console.log("差集:"+diffrenceKeySets);  
        checkedKeys.value = [...diffrenceKeySets];
      }
    };

    const getParentCodes = (level, rightCode) => {
      let parentCodes = [];
      var count = rightCode.length / level;
      for (let i = 0; i < count - 1; i++) {
        var parentCode = rightCode.substring(0, (i + 1) * level);
        parentCodes[i] = parentCode;
      }
      return parentCodes;
    };

    const handleSaveAllowRight = (record) => {
      //console.log(checkedKeys);
      //获取权限Id
      let checkIds = checkedKeys.value;
      //console.log(checkIds);
      allowRightModalData.loading=true;
      if (checkIds) {
        updateRightsFromRole({
          roleId: allowRightModalData.formData.roleId,
          rightIds: checkIds,
        })
          .then((res) => {
            let rightIds = res.result;
            Msg.info(vueI18n.t("common.saved_successfully"));
          })
          .catch((err) => {
            Msg.info(vueI18n.t("common.failed"));
            throw err;
          })
          .finally(() => {
            allowRightModalData.loading=false;
          });
      } else {
        allowRightModalData.loading=false;
      }
    };
    /** 分配权限end */
    return {
      refEditModalForm,
      refAllowRightModalForm,
      columns,
      tableData,
      modalData,
      allowRightModalData,
      roleRules,
      rightTree,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      handleOpenAllRightModal,
      handleSaveAllowRight,
      handleSearch,
      handleOpenCreateModal,
      handleOpenEditModal,
      handleSave,
      handlePage,
      getLanguageName,
      checkRight,
      handleMenuClick,
    };
  },
});
</script>
<style lang="less" scoped>
.overflowList {
  width: 100%;
  height: 580px;
  overflow-y: auto;
}
.paging-center {
  text-align: center;
}
:deep(.input-group-mid .ant-input-group-addon) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
:deep(.input-group-mid-number) {
  border-radius: 0;
  width: 100%;
}
:deep(.input-group-end-number) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
:deep(.input-group-start-number) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
</style>